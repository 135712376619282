
/* 
 *Landing page
 */
/* @import url("//hello.myfonts.net/count/3d91fa");*/
 
@font-face {
  font-family: "BostonAngelBold-Bold";
  src: url('../assets/webFonts/BostonAngelBoldBold/font.woff2') format('woff2'), url('../assets/webFonts/BostonAngelBoldBold/font.woff') format('woff');
}
 .BostonAngelBoldBold {
	font-family: "BostonAngelBold-Bold";
}
h1.BostonAngelBoldBold{
  letter-spacing: 2px;
}
div.limit-bound{
  width:100%; 
  overflow:hidden;
}  
 
@media (max-width: 992px) { 
  div.limit-bound{ 
    height:200px; 
  }  
 }
@media (max-width: 768px) { 
  div.limit-bound{ 
    height:150px; 
  }  
 }
@media (max-width: 576px) {
  div.limit-bound{ 
    height:100px; 
  }  
}
 

.bg-darkpurple{background:#282465}
.box-img{z-index:1}
.box-text{z-index:3}

.site-header img{
  width:48px;
  height:48px
}



/* Animation of book */


.animation-book{z-index:2}

.book-box{
  min-height: 300px; }

.book-container {
  width: 1000px;
  height: 500px; 
  z-index:1;
}

.book {
  transform-style: preserve-3d;
  position: relative; 
  height: 500px; 
  backface-visibility: visible;
  transform: rotate(7deg);
}

.book .front{
	font-family: "BostonAngelBold-Bold";}
.book .title{
  margin-top:40%;
  width:50%;
  height:24px;
}

.front, .back, .page1, .page2, .page3, .page4, .page5, .page6 { 
  transform-style: preserve-3d;
  position: absolute;
  width: 350px;
  height: 100%;
  top: 0; left: 0;
  transform-origin: left center;
  transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
}

.back {
  background: #4f68b1;
}
.front {
background: rgb(79,104,177);
background: linear-gradient(90deg, rgba(79,104,177,1) 0%, rgba(64,83,138,1) 5%, rgba(79,104,177,1) 6%, rgba(79,104,177,1) 100%);
}
.front, .page1, .page3, .page5 {
  border-bottom-right-radius: .5em;
  border-top-right-radius: .5em;
}

.back, .page2, .page4, .page6 {
  border-bottom-right-radius: .5em;
  border-top-right-radius: .5em;
}

.page1 { 
  background: #efefef;
}

.page2 {
  background: #efefef;
}

.page3 {
  background: #f5f5f5;
}

.page4 {
  background: #f5f5f5;
}

.page5 { 
  background: #fdfdfd;
}

.page6 {
  background:#fafafa url(../assets/book-1.svg);
  background-size:100% auto;
}

.book:hover .front {
  transform: rotateY(-160deg) scale(1.1);
  box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page1 {
  transform: rotateY(-150deg) scale(1.1);
  box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page2 {
  transform: rotateY(-30deg) scale(1.1);
  box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page3 {
  transform: rotateY(-140deg) scale(1.1);
  box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page4 {
  transform: rotateY(-40deg) scale(1.1);
  box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page5 {
  transform: rotateY(-130deg) scale(1.1);
  box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page6 {
  transform: rotateY(-50deg) scale(1.1);
  box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .back {
  transform: rotateY(-20deg) scale(1.1);
}
 

/* Animation from contact box */
 
.animation-contact{z-index:2}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index:1
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: url(../assets/001-comment.svg);
    background-size:100% auto;
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}


/*
 * Footer
 */
 .blog-footer {
  padding: 2.5rem 0; 
  text-align: center;  
}
.blog-footer p:last-child {
  margin-bottom: 0;
}

/*
 * Case studies 
 */

.hero-works{height:250px}
.hero-works img{ 
  width:30%; 
} 

.name-emmap{
  min-height:100px
}

.bg-adobexd{
  background: #e7e7e7;
}
.slowd-box{
  max-height:340px 
}  
.hero-box, .bg-hero{
  background:hsl(0, 0%, 94%); 
}  
.hero{
  height:200px; 
  transform:  
    scale3d(0.3, 0.3, 0.3)
    translateY(-32em)
    translateX(-8em); 
}  

 

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent; 
  border-left: 12px solid #f8f9fa;
}
.dotted{
 border-style: dashed !important;
}
.icon {
  position: absolute; 
  transform: translate(-10%,-50%); 
}
.arrow {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 5px;
  background-color: #ccc; 
}

.arrow::after, .arrow::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 5px;
  right: -5px;
  background-color: #ccc;
}

.arrow::after {
  top: -6px;
  transform: rotate(45deg);
}

.arrow::before {
  top: 6px; 
  transform: rotate(-45deg);
}
.external{ 
  top: 12%;
  right: 10%; 
  width: 40px;
  height: 30px; 
}
.easy-top{ 
  transform: rotate(90deg);
  top: -20%;
  right: 0; 
  width: 40px;
  height: 30px; 
}
.easy-bottom{ 
  transform: rotate(270deg);
  bottom: -20%;
  right: 5.5%; 
  width: 40px;
  height: 30px; 
} 
.easy-right{  
  position: absolute;  
  bottom: -17%;
  right: -10%; 
  width: 40px;
  height: 130px; 
}
.easy-right .icon{ 
  transform: rotate(130deg); 
  width: 40px;
  height: 30px; 
}
.easy-right .icon .arrow::after,.easy-right .icon .arrow::before,.easy-right .icon .arrow, .easy-bottom .arrow, .easy-top .arrow, .easy-bottom .arrow::before, .easy-top .arrow::before, .easy-bottom .arrow::after, .easy-top .arrow::after{ 
background-color: #e6e6e6;
}
.easy-right .center-filler{ 
width: 5px; 
height: 72px; 
background-color: #e6e6e6;
transform: translate(20px,-8px);
}
.easy-right .top-filler{
  transform: rotate(310deg) translate(5px,5px); 
width: 5px; 
height: 35px; 
background-color: #e6e6e6; 
}

.trust-top{  
  transform: rotate(45deg); 
  top: -40px;
  left: 45px; 
  width: 40px;
  height: 30px; 
} 
.trust-bottom{  
  transform: rotate(315deg); 
  top: 18px;
  left: 30px; 
  width: 40px;
  height: 30px; 
} 
 